.congrats-popup {
    /*position: fixed;*/
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*top: 0;*/
    /*left: 0;*/
    right: 0;
    bottom: 0;
    display: flex;
    /*align-items: center;*/
    /*justify-content: center;*/
    background-color: rgba(238, 238, 238, 0.5);
    backdrop-filter: blur(2px);
}

.popup-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
}
