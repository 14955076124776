/* 스크롤바의 Track (바탕) */
::-webkit-scrollbar {
    width: 8px; /* 스크롤바 너비 */
    border-radius: 4px; /* 모서리 둥글게 */
}

/* 스크롤바의 Thumb (움직이는 부분) */
::-webkit-scrollbar-thumb {
    background-color: #888; /* Thumb 색상 */
    border-radius: 4px; /* 모서리 둥글게 */
}

/* 스크롤바의 Track (바탕)가 활성화되었을 때 */
::-webkit-scrollbar-track {
    background-color: transparent; /* Track 색상 */
}
body, html {
  overflow: hidden;
}

.overflow-y-overlay {
    overflow: overlay;
}