.cal-container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}

.cal-today {
    font-size: x-small;
    color: grey;
    font-weight: 600;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translateX(-50%);
}

.cal-dot-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 62%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.cal-done-dot {
    background-color: rgb(35, 193, 197);
    border-radius: 50%;
    width: 0.4rem;
    height: 0.4rem;
}

.cal-super-done-dot {
    background-color: rgb(35, 197, 105);
    border-radius: 50%;
    width: 0.4rem;
    height: 0.4rem;
    margin-left: 2px;
}

.react-calendar {
    width: 100%;
    border: none;
    border-radius: 0.5rem;
    box-shadow: 4px 2px 10px 0px rgba(0, 0, 0, 0.08);
    padding: 3% 5%;
    margin-bottom: 10px;
    background-color: white;
}

/* 전체 폰트 컬러 */
.react-calendar__month-view {
    & abbr {
        color: #2c2c2c;
        position: relative;
    }
}

/* 네비게이션 가운데 정렬 */
.react-calendar__navigation {
    justify-content: center;
}

/* 네비게이션 폰트 설정 */
.react-calendar__navigation button {
    font-weight: 800;
    font-size: 1rem;
}

/* 네비게이션 버튼 컬러 */
.react-calendar__navigation button:focus {
    background-color: white;
}

/* 네비게이션 비활성화 됐을때 스타일 */
.react-calendar__navigation button:disabled {
    background-color: white;
    color: darkgrey;
}

.react-calendar__navigation button:enabled:hover {
    background-color: transparent;
}

/* 년/월 상단 네비게이션 칸 크기 줄이기 */
.react-calendar__navigation__label {
    flex-grow: 0 !important;
}

.react-calendar__navigation__label__labelText {
    color: black;
}

/* 요일 밑줄 제거 */
.react-calendar__month-view__weekdays abbr {
    text-decoration: none;
    font-weight: 800;
}

/* 일요일에만 빨간 폰트 */
.react-calendar__month-view__weekdays__weekday--weekend abbr[title="일요일"] {
    color: #0c8d8f;
}

/* 오늘 날짜 폰트 컬러 */
.react-calendar__tile--now {
    background: none;

    & abbr {
        /*color: #2d2d2d;*/
        font-weight: bold;
    }
}

/* 네비게이션 월 스타일 적용 */
.react-calendar__year-view__months__month {
    border-radius: 0.8rem;
    background-color: white;
    padding: 0;
}

/* 네비게이션 현재 월 스타일 적용 */
.react-calendar__tile--hasActive {
    background-color: #ddf6e8;

    & abbr {
        color: black;
    }
}

/* 일 날짜 간격 */
.react-calendar__tile {
    padding: 5px 0px 18px;
    position: relative;
}

/* 네비게이션 월 스타일 적용 */
.react-calendar__year-view__months__month {
    flex: 0 0 calc(33.3333% - 10px) !important;
    margin-inline-start: 5px !important;
    margin-inline-end: 5px !important;
    margin-block-end: 10px;
    padding: 20px 6.6667px;
    font-size: 0.9rem;
    font-weight: 600;
    color: black;
}

/* 선택한 날짜 스타일 적용 */
/*.react-calendar__tile:enabled:hover,*/
.react-calendar__tile:enabled:focus,
.react-calendar__tile--active {
    background-color: #ddf6e8;
    border-radius: 0.3rem;
    color: #1e1e1e;
}

.react-calendar__tile:enabled:hover {
    background-color: #c0efd3;
    border-radius: 0.3rem;
}

.react-calendar__tile:disabled {
    background-color: #f8f8f8;
    & abbr {
        color: #a4a4a4;
    }
}
