@tailwind base;
@tailwind components;
@tailwind utilities;
/* App.css */
@layer components {

    body, html {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        word-break: keep-all;
    }

    /*스크롤바 숨기기*/
    ::-webkit-scrollbar {
        display: none;
    }
    body {
        -ms-overflow-style: none; /* 익스플로러 스크롤바 옵션 */
        scrollbar-width: none; /* 파이어폭스 스크롤바 옵션 */
    }

    h1 {
        font-weight: bold;
        font-size: 1.8em;
        margin: 25px;
    }

    h2 {
        font-weight: bold;
        font-size: 1.2em;
        margin: 15px;
    }

    div {
        outline: none;
    }

    .main-container {
        width: 90vw; /* 최소 너비 설정 */
        min-width: 300px; /* 최대 너비 설정 */
        max-width: 380px; /* 최대 너비 설정 */

        height: 90vh;
        max-height: 900px; /* 최소 높이 설정 */
        min-height: 600px; /* 최소 높이 설정 */

        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        box-shadow: rgba(137, 137, 137, 0.27) 15px 15px 30px;
        border-radius: 40px;
        border: #2c2c2c solid 1px;
    }

    @media (max-width: 768px) {
        .main-container {
            width: 100vw;
            min-width: 100vw;
            max-width: 100vw;

            height: 100vh;
            max-height: 100vh;
            min-height: 100vh;

            margin: 0;
            border-radius: 0;
            box-shadow: none;
            border: none;
        }

        .background-container {
            display: none;
        }
    }

    .background-container {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -10;
    }

    .container {
        width: 100%;
        padding: 20px;
    }

    input {
        border-bottom: #dddddd solid 1px;
        width: 100%;
        padding: 5px 10px;
    }
    input:focus {
        outline: none;
    }

    .input-title {
        font-size: 1rem;
        font-weight: bold;
        color: #1e1e1e;

        margin-bottom: 3px;
    }

    .input-wrap {
        width: 100%;
        margin-bottom: 30px;
    }

    .content-title {
        font-size: 1rem;
        font-weight: bold;
        color: #1e1e1e;

        margin-bottom: 3px;
    }

    .content-wrap {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between ;
    }



    .btn-main, .btn-sub, .btn-special, .btn-extra, .btn-finish {
        width: 100%;
        height: 2.8rem;
        margin-bottom: 10px;
        padding: 10px 15px;
        border-radius: 10px;
        font-weight: bold;
    }

    .btn-main {
        color: white;
        background-color: #caf1f5;
        background-color: #0bb8bc;
    }
    .btn-sub {
        color: white;
        background-color: #9b9b9b;

    }
    .btn-special {
        color: white;
        background-image: linear-gradient(140deg, #0bb8bc, #22c55e 70%);
        box-shadow: rgba(5, 255, 98, 0.5) 0px 0px 12px;
        transition: opacity 0.3s ease-in-out;
    }
    .btn-finish {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 1.3rem;
        background-image: linear-gradient(140deg, #ef3c1c, #ff982f 70%);
        box-shadow: rgba(255, 213, 5, 0.54) 0px 0px 12px;
        transition: opacity 0.3s ease-in-out;
    }
    .btn-extra {
        color: #888888;
        font-weight: normal;
        text-decoration: underline;

    }
    .btn-show {
        opacity: 1;
        transform: translateY(0);
    }



    .btn-row {
        display: flex;
    }

    .btn-col {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;

        display: flex;
        flex-direction: column;
    }

    .error-msg {
        color: red;
        font-size: 0.75rem;
        height: 1rem;
        word-break: keep-all;
        font-weight: bolder;
    }

    .wrap-goal {
        font-size: 1.6rem;
        text-align: center;
        font-weight: bold;

        height: 5rem;
    }

    .done-goal {
        color: #b4b4b4;
        font-size : 1.2rem;
        font-weight: normal;
        text-decoration: line-through;
    }

    .super-done-goal {
        font-size : 2rem;
        font-weight: normal;
        text-decoration: line-through;
    }



    .goal-content.original {
        /*text-shadow: rgba(5, 255, 98, 0.24) 0px 0px 5px;*/
    }

    .popup-animation {
        animation: scaleAnimation 500ms cubic-bezier(0.4,0,0.2,1);
    }

    @keyframes scaleAnimation {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }

}









