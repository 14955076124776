@keyframes slideDown {
    0% {
        max-height: 0;
        opacity: 0;
    }
    100% {
        max-height: 100px;
        opacity: 1;
    }
}

.slide-down {
    animation: slideDown 0.5s ease-out forwards;
    overflow: hidden;
}

.stand-by {
    transition: max-height 0.6s ease-in-out, opacity 0.6s ease-in-out;
    overflow: hidden;
    max-height: 0; /* 초기 높이 설정 */
    opacity: 0; /* 초기 불투명도 설정 */
}

.stand-by.show {
    max-height: 80px; /* 적절한 최대 높이로 조정 */
    opacity: 1; /* 불투명도 설정 */
    overflow: visible;

}

.input-wrap.stand-by {
    margin-bottom: 0px;
}

.input-title.create-goal {
    text-align: center;
    margin-bottom: 10px;
    font-size: 1.6rem;
}



@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.fade-in-out {
    animation: fadeInOut 2s ease-in-out forwards;
}
