.floating-circle-cyan-1 {
    z-index: -1;
    position: absolute;
    top: 50%;
    left: -100px; /* 시작 위치 */
    width: 600px; /* 원의 지름 */
    height: 600px; /* 원의 지름 */
    background-color: rgba(70, 252, 255, 0.5); /* 배경 색상 */
    border-radius: 50%; /* 원 모양으로 만듦 */
    filter: blur(50px);
    animation: moveToRightWithBlink 20s linear infinite; /* 애니메이션 적용 */
}

@keyframes moveToRightWithBlink {
    0% {
        transform: translateX(-600px); /* 시작 위치와 크기 */
        opacity: 0.1; /* 투명도 */
    }
    50% {
        transform: translateX(calc(100% + 100px)); /* 종료 위치와 크기 */
        opacity: 0.35; /* 투명도 */
    }
    100% {
        transform: translateX(calc(200% + 100px)); /* 다시 시작 위치와 크기 */
        opacity: 0.1; /* 투명도 */
    }
}


.wave {
    opacity: .2;
    position: fixed;
    top: 80%;
    left: calc(50% - 500vh/2);
    background: #0bb8bc;
    width: 500vh;
    height: 500vh;
    transform-origin: 50% 49.5%;
    /*border: black 1px solid;*/
    border-radius: 50%;
    animation: drift 12s infinite linear;
}

.wave.-three {
    animation: drift 25s infinite linear;
    margin-top: -25px;
    margin-left: 100px;
}

.wave.-two {
    animation: drift 10s infinite linear;
    opacity: .1;
    margin-top: -35px;
    margin-left: -100px;
    /*background: transparent;*/
}
@keyframes drift {
    from { transform: rotate(0deg); }
    from { transform: rotate(360deg); }
}


.box {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 30px rgba(black, .2);
    background: lighten(#f0f4c3, 10%);
    position: fixed;
    bottom: 0;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
}
/*.box:after {*/
/*    content: '';*/
/*    display: block;*/
/*    left: 0;*/
/*    top: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    background: linear-gradient(to bottom, rgba(#e8a, 1), rgba(#def, 0) 80%, rgba(white, .5));*/
/*    z-index: 11;*/
/*    transform: translate3d(0, 0, 0);*/
/*}*/

