.tab-bar {
    display: flex; /* 수평으로 배치하기 위해 flex 사용 */
    justify-content: space-evenly; /* 탭들을 수평 중앙으로 정렬 */
    position: relative; /* 화면 하단에 고정 */
    bottom: 0; /* 화면 하단에 고정 */
    width: 100%; /* 탭바 전체 너비 */
    background-color: #ffffff; /* 배경색 지정 */
    border-top: 1px solid #dddddd; /* 상단에 회색 선 추가 */
}

.tab {
    padding: 10px 20px; /* 각 탭의 내부 여백 */
    display: flex; /* 내부 아이템을 가로로 배치하기 위해 flex 사용 */
    align-items: center; /* 아이콘과 레이블을 세로 중앙 정렬 */
}

.tab:not(:last-child) {
    margin-right: 20px; /* 마지막 탭을 제외한 나머지 탭들 간의 간격 */
}

.tab-icon {
    width: 25px;
    height: 25px;
    fill: #363636;
}


.tab-icon.calendar{
    width: 23px;
    height: 24px;
    margin-bottom: 1px;
}

.tab-text {
    color: #363636;
}

.tab-icon.center {
    width: 35px;
    height: 35px;
    fill: #ffffff;
}
.tab-text.center {
    color: #ffffff;
}
